import { State } from '../state/state';

export interface ComponentProps {
    element?: Element | null;
}

export class Component<T extends ComponentProps = ComponentProps> {
    element: T['element'] = null;
    mounted: State<boolean>;
    props: T;

    constructor(props: T) {
        this.props = props ?? {};
        this.element = props.element ?? null;
        this.mounted = State.of(false);
    }

    public mount(): this {
        const noElement = !this.element || !Element.prototype.isPrototypeOf(this.element);
        if (noElement) {
            throw new Error("COMPONENT: `element` isn't instance of `Element`.");
        }
        if (this.mounted.is(false)) {
            this.onMount();
            this.mounted.set(true);
        }
        return this;
    }

    public unmount(): this {
        if (this.mounted.is(true)) {
            this.onUnmount();
            this.mounted.set(false);
        }
        return this;
    }

    protected onMount(onSuccess?: (...args: unknown[]) => void, onFailure?: (...args: unknown[]) => void): void {}

    protected onUnmount(onSuccess?: (...args: unknown[]) => void, onFailure?: (...args: unknown[]) => void): void {}

    static of<T extends ComponentProps, U extends Component<T>>(this: new (props: T) => U, props: T): U {
        return new this(props);
    }
}

// TODO: write tests for this function!
export const unmountComponent = (component) => component?.unmount();
