const getScrollbarWidth = () => {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.visibility = 'hidden';
    scrollDiv.style.overflow = 'scroll';

    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
};

/**
 * Create a function to get the zoom level on desktop browsers
 * @param [subtractScrollbar=true] - Whether to subtract the scrollbar width from the outer width
 * @returns Function to get the zoom level
 */
export const makeZoomLevelGetter = (subtractScrollbar = true) => {
    const scrollbarWidth = subtractScrollbar ? getScrollbarWidth() : 0;

    return () => {
        const outerWidth = window.outerWidth - scrollbarWidth;
        return Math.round((outerWidth / window.innerWidth) * 100) / 100;
    };
};
