import { start } from '../js/common/start';

// vendor
import vConfig from '../js/common/vendor/config';
import vHelpers from '../js/common/vendor/helpers';
import vStart from '../js/common/vendor/start';
import vMisc from '../js/booking/misc';

// Libs
import { ComponentLibrary } from '../libs/engine/component-library';
import { HellEngine } from '../libs/engine/hell-engine';

// Components
import { GlobalNavigation } from '../js/common/components/global-navigation';
import { Rotunda } from '../cms/js/components/rotunda/rotunda';
import { BookButton } from '../js/common/libs/book-button';

import '../common/js/utils/library-lazy-loader';

start(() => {
    // Vendor
    vConfig();
    vHelpers();
    vStart();
    vMisc();
});

(() => {
    const library = new ComponentLibrary();
    library.set('.js-GlobalNavigation', GlobalNavigation);
    library.set('.js-Rotunda', Rotunda);
    library.set('.js-BookButton', BookButton);
    HellEngine.of({ library }).start();
})();
