import { Component } from '@libs/component/component';
import { GATracker } from '@libs/ga-tracker';
import { globalCurrencyLinksObserver } from '@states/currency-subject/currency-links-observer';

export class BookButton extends Component {
    currencyObserver = globalCurrencyLinksObserver;

    onMount() {
        this.currencyObserver.register(this.element as HTMLAnchorElement);

        const propsName = (this.element as HTMLAnchorElement)?.dataset.bookGaName;

        if (!propsName) {
            return;
        }

        GATracker.trackDynamic(this.element, () => {
            return JSON.parse(this.element?.getAttribute(propsName) as string);
        });
    }

    onUnmount() {
        this.currencyObserver.unregister(this.element as HTMLAnchorElement);
    }
}
