import type { VVCurrencyState, VVCurrencies } from './types';
import { currenciesSchema } from './types';

export class CurrencyBuilder {
    private validate(state: unknown): asserts state is VVCurrencies {
        currenciesSchema.parse(state);
    }

    build(state: unknown): VVCurrencyState {
        this.validate(state);
        const availableCurrencies = state.availableCurrencies;
        const selectedCurrency = state.availableCurrencies.find((currency) => currency.code === state.currencyCode);
        if (!selectedCurrency) {
            throw new Error(`The selected currency is not in the list of available currencies.`);
        }
        return { availableCurrencies, selectedCurrency };
    }
}
