import { getServerOffset } from './formatters';

export type DirtyDate = Date | string | number | null | undefined;
export type DirtyDateTuple = [
    number, // year
    number, // month
    number | null | undefined, // day
    number | null | undefined, // hour
    number | null | undefined, // minute
    number | null | undefined, // second
    number | null | undefined // millisecond
];

const checkDirtyDateTuple = (value: unknown): value is DirtyDateTuple => Array.isArray(value) && value.length > 1;

const handleDate = (args: Array<DirtyDate> | DirtyDateTuple): Date | null =>
    args.length === 1 && args[0] instanceof Date && !Number.isNaN(args[0].getTime()) ? args[0] : null;

/**
 * The `handleManual` accepts date components in the New York timezone and returns a `Date` in
 * a user's timezone. It goes against the basic `Date` constructor, which treats passed date
 * components as date components in the user's timezone.
 *
 * @example
 *
 * handleManual([2023, 9, 10, 20, 0, 0]); // `2023-10-11T01:00:00Z` in EST (-05:00)
 * handleManual([2023, 5, 2, 8, 0, 0]); // `2023-06-02T12:00:00Z` in EDT (-04:00)
 *
 */
const handleManual = (args: Array<DirtyDate> | DirtyDateTuple): Date | null => {
    const isTuple = checkDirtyDateTuple(args);
    if (!isTuple) {
        return null;
    }
    const [year, month, day, hour, minute, second, millisecond] = args;
    const localDate = new Date(year, month, day ?? 1, hour ?? 0, minute ?? 0, second ?? 0, millisecond ?? 0);
    const offset = getServerOffset(localDate);
    return new Date(localDate.valueOf() + offset.msToLocal);
};

const handleNow = (args: Array<DirtyDate> | DirtyDateTuple): Date | null =>
    args[0] === undefined ? new Date(Date.now()) : null;

const handleTimestamp = (args: Array<DirtyDate> | DirtyDateTuple): Date | null =>
    args.length === 1 && typeof args[0] === 'number' && !Number.isNaN(args[0]) && isFinite(args[0])
        ? new Date(args[0])
        : null;

const handleIsoWithTimezone = (args: Array<DirtyDate> | DirtyDateTuple): Date | null => {
    const value = args[0];
    const isIsoWithTimezone =
        args.length === 1 &&
        typeof value === 'string' &&
        value.length >= 11 &&
        value.match(/^\d{4}-\d{2}-\d{2}(Z|T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|([+-]\d{2}:\d{2})))$/) !== null;
    return isIsoWithTimezone ? new Date(value) : null;
};

/**
 * The website operates in EST/EDT timezone, so any ISO date-only dates (without timezone)
 * is treated as a date in EST/EDT.
 */
const handleIsoWithoutTimezone = (args: Array<DirtyDate> | DirtyDateTuple): Date | null => {
    const value = args[0];
    const isValidStr = args.length === 1 && typeof value === 'string' && value.length >= 10;
    const match = isValidStr ? value.match(/^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2})(?:\.\d{3})?)?$/) : null;
    if (match === null) {
        return null;
    }
    const dirtyDateTuple = match.slice(1, 7).map((rawValue, index) => {
        const value = rawValue && parseInt(rawValue, 10);
        const isMonth = index === 1 && typeof value === 'number';
        return isMonth ? value - 1 : value; // December in ISO format is 12 in the mean time it's 11 in plane JS.
    });
    return checkDirtyDateTuple(dirtyDateTuple) ? handleManual(dirtyDateTuple) : null;
};

/**
 * The method ensures that ISO date strings without a time zone are treated as EST/EDT dates,
 * and when date components (year, month, day, etc.) are provided, they are interpreted as being in the EST/EDT
 * time zone rather than the user's local time zone.
 *
 * @see https://virginvoyages.atlassian.net/wiki/spaces/PODS/pages/4496490536/Dates
 */
export function toLocalDate(dirtyDate?: DirtyDate): Date | null;
export function toLocalDate(
    year: number,
    month: number,
    day?: number | null,
    hour?: number | null,
    minute?: number | null,
    second?: number | null
): Date | null;
export function toLocalDate(...args: Array<DirtyDate> | DirtyDateTuple): Date | null {
    return (
        handleNow(args) ||
        handleDate(args) ||
        handleTimestamp(args) ||
        handleIsoWithTimezone(args) ||
        handleIsoWithoutTimezone(args) ||
        handleManual(args)
    );
}
