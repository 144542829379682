import { getEl } from '../../utils/getEl';

export class RotundaRing {
    private el: SVGElement | null;
    private pathEl: SVGPathElement | null = null;
    private textEl: SVGTextElement | null = null;
    private readonly correctionRatio = 1; // corrects the final letter spacing value.

    private updateLetterSpacing = (): void => {
        if (!this.pathEl || !this.textEl) {
            return;
        }
        this.textEl?.style.removeProperty('letter-spacing');
        const textLength = this.textEl.getComputedTextLength();
        const charNumb = this.textEl.getNumberOfChars();
        const charSize = textLength / charNumb;
        const endSpace = charSize * this.correctionRatio;
        const delta = this.pathEl.getTotalLength() - (textLength + endSpace);
        const fontSize = parseFloat(window.getComputedStyle(this.textEl).fontSize);
        const value = delta / (charNumb + this.correctionRatio) / fontSize;
        this.textEl.style.setProperty('letter-spacing', `${value}em`);
    };

    constructor(el: SVGElement | null) {
        this.el = el;
        this.pathEl = getEl(this.el, '.js-RotundaRingPath', SVGPathElement);
        this.textEl = getEl(this.el, '.js-RotundaRingText', SVGTextElement);
        this.updateLetterSpacing();
        window.addEventListener('resize', this.updateLetterSpacing);
    }

    destroy(): void {
        window.removeEventListener('resize', this.updateLetterSpacing);
        this.textEl?.style.removeProperty('letter-spacing');
        this.textEl = null;
        this.pathEl = null;
        this.el = null;
    }

    static of(el: SVGElement | null): RotundaRing {
        return new RotundaRing(el);
    }
}
