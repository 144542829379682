import type { VoyageObservers } from './observers';
import type { VoyageNextObserver, VoyageObserver } from './observer';
import { Observers } from './observers';

export type VoyageSelector<T, R> = (value: T) => R;

export type VoyageSubscription = {
    unsubscribe(): boolean;
};

export interface VoyageObservable<T> {
    get<R>(selector?: VoyageSelector<T, R>): T | R;
    subscribe(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): VoyageSubscription;
    unsubscribe(observerOrNext?: VoyageObserver<T> | VoyageNextObserver<T>): boolean;
}

export abstract class Observable<T> implements VoyageObservable<T> {
    protected value: T;
    protected observers: VoyageObservers<T> = new Observers<T>();

    get(): T;
    get<R>(selector: VoyageSelector<T, R>): R;
    get<R>(selector?: VoyageSelector<T, R>): T | R {
        return typeof selector === 'function' ? selector(this.value) : this.value;
    }

    subscribe(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): VoyageSubscription {
        const observer = this.observers.get(observerOrNext);
        if (observer) {
            return this.buildSubscription(observerOrNext);
        }
        const currentObserver = this.observers.set(observerOrNext);
        currentObserver.next(this.value);
        return this.buildSubscription(observerOrNext);
    }

    unsubscribe(observerOrNext?: VoyageObserver<T> | VoyageNextObserver<T>): boolean {
        if (observerOrNext) {
            return this.observers.delete(observerOrNext);
        } else {
            return this.observers.clear();
        }
    }

    private buildSubscription(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): VoyageSubscription {
        return {
            unsubscribe: () => this.unsubscribe(observerOrNext),
        };
    }
}
