import type { Component } from '@libs/component/component';
import { getAllEl } from '@utils/getEl';

export const unmountComponent = (component: Component) => {
    component.unmount?.();
};

export const checkArea = (areaEl: Element) => {
    return (componentEl: Element) => {
        const closestArea = componentEl.closest('.js-CMSArea');

        return !closestArea || closestArea === areaEl;
    };
};

export const mountComponent = (Component: any) => {
    return (element: Element) => {
        return Component.of({ element }).mount();
    };
};

export const queryComponents = (areaEl: Element, selector: string) => {
    return getAllEl(areaEl, selector);
};
