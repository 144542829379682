export type VoyageNextObserver<T> = (value: T) => void;

export interface VoyageObserver<T> {
    next(value: T): void;
}

export class Observer<T> implements VoyageObserver<T> {
    private instance: VoyageObserver<T>;

    constructor(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>) {
        this.instance = typeof observerOrNext === 'function' ? { next: observerOrNext } : observerOrNext;
    }

    next(value: T): void {
        this.instance.next(value);
    }
}
