import { Engine } from './engine';
import { unmountComponent } from './engine-utils';

const queryComponents = (selector) => Array.from(document.querySelectorAll(selector));
const onlyUnmounted = (componentList) => (element) =>
    componentList.every((component) => component.element !== element && !element.__failedToMount);
const mountComponent = (componentList, Component) => (element) => {
    try {
        componentList.push(Component?.of?.({ element }).mount?.());
    } catch (e) {
        element.__failedToMount = true;
        throw e;
    }
};
const onlyGlobalComponents = (componentEl) => !componentEl.closest('.js-CMSArea');
const findAndMount = (componentList) => (Component, selector) =>
    queryComponents(selector)
        .filter(onlyGlobalComponents)
        .filter(onlyUnmounted(componentList))
        .forEach(mountComponent(componentList, Component));
const checkComponent =
    (isExist = true) =>
    (component) =>
        document.body.contains(component.element) === isExist;
const existingComponents = checkComponent(true);
const nonExistingComponents = checkComponent(false);
const mountManually = (library, components) => library.forEach?.(findAndMount(components));
const some = (...filters) => (component) => filters.some((filter) => filter(component));
const every = (...filters) => (component) => filters.every((filter) => filter(component));
const not = (filter) => (component) => !filter(component)
const handleMutation = (library, components) => (mutations) => {
    const elementsWithRemovedClassnames = mutations
        .filter(({ attributeName }) => attributeName === 'class')
        .reduce((map, { target, oldValue }) => {
            const removedLibraryKeys = oldValue?.split(' ')
                .filter((className) => !!className && !target.classList.contains(className))
                .map((removedClassName) => `.${removedClassName}`) ?? [];
            if (removedLibraryKeys.length) {
                map.set(target, removedLibraryKeys);
            }
            return map;
        }, new Map());

    const componentsUnmountedByMutation = components.filter((component) => {
        const selectors = elementsWithRemovedClassnames.get(component.element);
        if (!selectors || !selectors.length) {
            return false;
        }
        return selectors.some(selector => library.has(selector))
    });

    const unmountedByMutationComponents = (component) => componentsUnmountedByMutation.includes(component);
    components.filter(some(nonExistingComponents, unmountedByMutationComponents)).forEach(unmountComponent);
    components = components.filter(every(existingComponents, not(unmountedByMutationComponents)));
    mountManually(library, components);
};

export class HellEngine extends Engine {
    kickStart = () => {
        this.observer?.observe(document.body, {
            attributes: true,
            characterData: true,
            childList: true,
            subtree: true,
            attributeOldValue: true,
            characterDataOldValue: true,
        });
        mountManually(this.library, this.components);
    };

    /** @protected */
    onStart() {
        this.components = [];
        if (!this.observer) {
            this.observer = new MutationObserver(handleMutation(this.library, this.components));
        }
        const isPageReady = /comp|inter|loaded/.test(document.readyState);
        if (isPageReady) {
            this.kickStart();
            return;
        }
        document.addEventListener('DOMContentLoaded', this.kickStart, { once: true });
    }

    /** @protected */
    onStop() {
        this.components = this.components.forEach(unmountComponent);
        this.observer?.disconnect();
    }
}
