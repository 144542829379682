import getPage, { getMappedPage } from '../js/cms/helpers/routesMapping';
import { getAllEl } from '../cms/js/utils/getEl';

const ITEM_CLASS_NAME = '.js-gaTracker';
const pageTitlePattern = /__pageTitle__/g;
const pagePattern = /__path__/g;

let pageTitle: string = '';

export function toGaString(value: string) {
    return value.replace(/( - | |')/g, '-').toLowerCase();
}

export const getPageTitle = (): string => {
    if (!pageTitle) {
        const mappedPage = getMappedPage();

        if (mappedPage) {
            pageTitle = mappedPage;

            return pageTitle;
        }

        const h1 = document.querySelector('h1');

        pageTitle = h1 ? toGaString(h1.innerText || h1.innerHTML) : getPage();
    }

    return pageTitle;
};

const getPropsBase = (item: HTMLElement): Record<string, unknown> => {
    const props = item ? item.dataset.gaProperties : '';
    return props ? JSON.parse(props) : {};
};

const push = (item: HTMLElement | null, getProps: Function): void => {
    const props = getProps(item);

    for (let p in props) {
        props[p] = props[p].replace(pageTitlePattern, getPageTitle()).replace(pagePattern, getPage());
    }

    if (props) {
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        window.dataLayer.push(props);
    }
};

const trackInternal = (item: HTMLElement, getProps = getPropsBase): void => {
    item.addEventListener('click', () => push(item, getProps));
};

const getItems = (items: any): Array<HTMLElement> => {
    return [...(typeof items[Symbol.iterator] === 'function' ? items : [items])];
};

interface PushProps {
    item?: Element | null;
    getProps?: Function;
}

export class GATracker {
    static track(el?: Element): typeof GATracker {
        const items = getAllEl(el || document, ITEM_CLASS_NAME, HTMLElement);

        items.forEach((item) => trackInternal(item));
        return this;
    }

    static trackDynamic(items: unknown, getProps = getPropsBase): typeof GATracker {
        getItems(items).forEach((item) => trackInternal(item, getProps));

        return this;
    }

    static push({ item = null, getProps = getPropsBase }: PushProps): typeof GATracker {
        push(<HTMLElement>item, getProps);

        return this;
    }

    static pushStatic(record?: Record<string, string>): typeof GATracker {
        if (record) {
            GATracker.push({ item: null, getProps: () => record })
        }
        return this;
    }

    static isPushed(eventCategory: string | number | boolean): boolean {
        return window.dataLayer.some((record) => record.eventCategory === eventCategory);
    }

    static parseRawInput = (record?: string | null): Record<string, string> | undefined => {
        try {
            if (record) {
                return JSON.parse(record);
            } else {
                return undefined;
            }
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    static parseAndPush = (record?: string | null): typeof GATracker =>
        GATracker.pushStatic(GATracker.parseRawInput(record));
}
