// deprecated. please use 'js/common/utils/config'
export const COOKIES = {
    currency: {
        name: 'currency',
    },
};

export default () => {
    (function (global) {
        global.vv = global.vv || {};

        global.vv.config = global.vv.config || {};
        global.vv.config = {
            cookies: {
                currency: {
                    name: COOKIES.currency.name,
                },

                newsletter: {
                    name: 'newsletter_signed_up',
                    expirationDays: 30,
                },
            },

            sessionVariables: {
                emailSignupModal: {
                    name: 'emailSignupModal_isShown',
                },

                lookupService: {
                    lookupInfo: 'lookupInfo',
                },
            },

            localStorageVariables: {
                authorization: {
                    name: 'Authorization',
                },
            },

            pageTypes: {
                sweepstakes: 'sweepstakes',
            },
        };
    })(window);
};
