import Cookies from 'js-cookie';

export default () => {
    (function (global) {
        global.vv.helpers = {
            cookiesAPI: {
                get: getCookie,
                set: setCookie,
            },

            sendRequest: sendRequest,
            getQueryVariable: getQueryVariable,
            getPageType: getPageType,
        };

        function getCookie(name) {
            return Cookies.get(name);
        }

        function setCookie(name, value, options) {
            options = options || {};

            var expires = options.expires;

            if (typeof expires == 'number' && expires) {
                const d = new Date();

                d.setTime(d.getTime() + expires * 1000);

                expires = options.expires = d;
            }

            if (!options.samesite) {
                options.samesite = 'None';
                options.secure = true;
            }

            Cookies.set(name, encodeURIComponent(value), options);
        }

        function sendRequest(url, type, data, onSuccess, onError) {
            const xhr = new XMLHttpRequest();
            const isGet = type === 'GET';

            xhr.open(type, isGet ? url + jsonToQueryString(data) : url, true);
            xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');

            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    if (xhr.status == 200) {
                        onSuccess && onSuccess(xhr.response);
                    } else {
                        onError && onError();
                    }
                }
            };

            xhr.send(isGet ? undefined : data);

            function jsonToQueryString(json) {
                return json
                    ? '?' +
                          Object.keys(json)
                              .map(function (key) {
                                  return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                              })
                              .join('&')
                    : '';
            }
        }

        function getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split('&');
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (decodeURIComponent(pair[0]) == variable) {
                    return decodeURIComponent(pair[1]);
                }
            }
        }

        function getPageType() {
            var page;

            for (page in global.vv.config.pageTypes) {
                if (global.document.body.classList.contains(page)) {
                    return page;
                }
            }
        }
    })(window);
};
