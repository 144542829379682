import { State } from '../state/state';

export type EngineProps = {
    library?: Map<string, any>;
};

export class Engine<T extends EngineProps> {
    protected library: Map<string, any>;
    private status: State;

    constructor(props: EngineProps) {
        this.library = props.library || new Map();
        this.status = State.of('initialized');
    }

    public start(): Engine<T> {
        if (this.status.get() === 'running') {
            return this;
        }
        this.onStart();
        this.status.set('running');
        return this;
    }

    public stop(): Engine<T> {
        if (this.status.get() === 'stopped') {
            return this;
        }
        this.onStop();
        this.status.set('stopped');
        return this;
    }

    protected onStart(onSuccess?: any, onFailure?: any): void {}

    protected onStop(onSuccess?: any, onFailure?: any): void {}

    static of<T extends EngineProps>(props: T): Engine<T> {
        return new this(props) as Engine<T>;
    }
}
