import { Component } from '../../../../libs/component/component';
import { getAllEl, getEl } from '../../utils/getEl';
import { RotundaCountdown } from './rotunda-countdown';
import { RotundaRing } from './rotunda-ring';
import { RotundaTracking } from './rotunda-tracking';
import { RotundaVisibility } from './rotunda-visibility';

export class Rotunda extends Component {
    private countdown = new RotundaCountdown();
    private rings: RotundaRing[] = [];
    private tracking = new RotundaTracking();
    private visibility = new RotundaVisibility();

    onMount(): void {
        this.countdown.init(getEl(this.element, '.js-RotundaCountdown', HTMLElement));
        this.rings = getAllEl(this.element, '.js-RotundaRing', SVGElement).map(RotundaRing.of);
        this.tracking.init(this.element);
        this.visibility.init(this.element);
    }

    onUnmount(): void {
        this.visibility.destroy();
        this.tracking.destroy();
        this.rings.forEach((ring) => ring.destroy());
        this.rings = [];
        this.countdown.destroy();
    }
}
