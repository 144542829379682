import equal from 'fast-deep-equal/es6';

const isFunction = (value: any): value is Function => typeof value === 'function';
const isObject = (value: any): value is Object =>
    value !== null && (typeof value === 'object' || typeof value === 'function');

export class State<T extends any = any> {
    private value: T;
    private observers?: Function[];

    constructor(value: T = null as any) {
        this.value = value;
    }

    get(): T {
        return this.value;
    }

    is(comparedValue: any) {
        return isObject(comparedValue) && isObject(this.value)
            ? equal(comparedValue, this.value)
            : comparedValue === this.value;
    }

    set(newValue: T) {
        if (!this.is(newValue)) {
            const prevValue = this.value;
            this.value = newValue;
            this.observers?.forEach((callback) => callback(this.value, prevValue));
        }
        return this;
    }

    update<K extends keyof T>(key: K, newValue: T[K]) {
        if (!isObject(this.value)) {
            throw new Error('State value should be an object to use update method');
        }

        this.set({ ...this.value, [key]: newValue });
    }

    getKey<k extends keyof T>(key: k): T[k] {
        return this.value[key];
    }

    subscribe(fn: (...params: T[]) => any) {
        const noObservers = !this.observers;
        if (noObservers) {
            this.observers = [];
        }
        const isFnc = isFunction(fn);
        const isUnique = !this.observers?.some((callback) => callback === fn);
        if (isFnc && isUnique) {
            this.observers?.push(fn);
            fn(this.get(), this.get());
        }
        return this;
    }

    unsubscribe(fn: Function) {
        const noObservers = !this.observers;
        if (noObservers) {
            return this;
        }
        this.observers = this.observers?.filter((observer) => observer !== fn);
        const emptyObservers = this.observers?.length === 0;
        if (emptyObservers) {
            this.observers = undefined;
        }
        return this;
    }

    static of<T extends any = any>(...props: T[]): State<T> {
        return new State(...props);
    }
}
