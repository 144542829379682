import { type VoyageObserver } from '../../../../common/js/utils/subject';
import { type VVCurrencyState } from './types';
import { currencySubject } from './currency-subject';

export interface GlobalCurrencyLinksObserver {
    register(anchors: HTMLAnchorElement | Array<HTMLAnchorElement> | null): void;

    unregister(anchors: HTMLAnchorElement | Array<HTMLAnchorElement> | null): void;
}

function applyToAll<T>(elems: T | Array<T | null> | null, fn: (elem: T) => void): void {
    if (!elems) {
        return;
    }
    if (Array.isArray(elems)) {
        elems.forEach((elem: T): void => {
            if (elem) {
                fn(elem);
            }
        });
    } else {
        fn(elems);
    }
}

class CurrencyLinksObserver implements VoyageObserver<VVCurrencyState>, GlobalCurrencyLinksObserver {
    private code: string = '';
    private readonly paramName: string = 'currencyCode';
    private readonly anchors: Set<HTMLAnchorElement> = new Set<HTMLAnchorElement>();

    register(anchors: HTMLAnchorElement | Array<HTMLAnchorElement> | null): void {
        applyToAll(anchors, (anchor: HTMLAnchorElement): void => {
            this.anchors.add(anchor);
            this.setUrlParameter(anchor);
        });
    }

    unregister(anchors: HTMLAnchorElement | Array<HTMLAnchorElement> | null): void {
        applyToAll(anchors, (anchor: HTMLAnchorElement): void => {
            this.anchors.delete(anchor);
        });
    }

    next({ selectedCurrency }): void {
        const isChanged = selectedCurrency.code !== this.code;
        if (!isChanged) {
            return;
        }
        this.code = selectedCurrency.code;
        this.anchors.forEach(this.setUrlParameter);
    }

    setUrlParameter = (anchor: HTMLAnchorElement): void => {
        if (!anchor.href) {
            return;
        }
        try {
            const url = new URL(anchor.href);
            // const paramName = anchor.getAttribute('data-currency-url-prop') ?? this.paramName; // it is commented because no one uses it.
            this.code ? url.searchParams.set(this.paramName, this.code) : url.searchParams.delete(this.paramName);
            anchor.href = url.href;
        } catch {
            console.debug(`bad href: ${anchor.href} for param: ${this.paramName} and code: ${this.code}`);
        }
    };
}

const sharedCurrencyLinksObserver = new CurrencyLinksObserver();
currencySubject.subscribe(sharedCurrencyLinksObserver);
export const globalCurrencyLinksObserver: GlobalCurrencyLinksObserver = sharedCurrencyLinksObserver;
