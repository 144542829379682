import { type VVDateDiff } from '../../../../common/js/utils/date-utils/date-utils';
import { CountdownSubject } from '../../../../common/js/utils/countdown/countdown-subject';
import { toLocalDate } from '../../../../common/js/utils/date-utils/to-local-date';
import { getEl } from 'cms/js/utils/getEl';
import z from 'zod';

const labelSchema = z.object({
    desktop: z.string(),
    mobile: z.string(),
});

const labelsSchema = z.object({
    ended: labelSchema,
    final: z.object({
        day: labelSchema,
        hours: labelSchema,
        minutes: labelSchema,
        seconds: labelSchema,
    }),
    ongoing: labelSchema,
});

export class RotundaCountdown {
    private countdown = new CountdownSubject();
    private el: HTMLElement | null = null;
    private endDate: Date | null = null;
    private digitsEl: HTMLElement | null = null;
    private labelDesktopEl: HTMLElement | null = null;
    private labelMobileEl: HTMLElement | null = null;
    private labels: z.infer<typeof labelsSchema> | null = null;

    private gatherData(el: HTMLElement | null): boolean {
        try {
            this.el = el;
            this.endDate = toLocalDate(this.el?.getAttribute('data-end-date'));
            this.digitsEl = getEl(this.el, '.js-RotundaCountdownDigits', HTMLElement);
            this.labelDesktopEl = getEl(this.el, '.js-RotundaCountdownLabelDesktop', HTMLElement);
            this.labelMobileEl = getEl(this.el, '.js-RotundaCountdownLabelMobile', HTMLElement);
            const labels = JSON.parse(this.el?.getAttribute('data-labels') ?? '{}');
            this.labels = labelsSchema.safeParse(labels) ? labels : null;
            return (
                !!this.el &&
                !!this.endDate &&
                !!this.digitsEl &&
                !!this.labelDesktopEl &&
                !!this.labelMobileEl &&
                !!this.labels
            );
        } catch {
            return false;
        }
    }

    private updateLabels({ desktop, mobile }: z.infer<typeof labelSchema> = { desktop: '', mobile: '' }): void {
        if (this.labelDesktopEl) {
            this.labelDesktopEl.innerHTML = desktop;
        }
        if (this.labelMobileEl) {
            this.labelMobileEl.innerHTML = mobile;
        }
    }

    private onTick = ({ days, hours, minutes, seconds }: VVDateDiff): void => {
        const timeLeft = days || hours || minutes || seconds || '-';
        if (this.digitsEl) {
            this.digitsEl.innerHTML = `${timeLeft}`;
        }
        if (days > 1) {
            this.updateLabels(this.labels?.ongoing);
        } else if (days === 1) {
            this.updateLabels(this.labels?.final.day);
        } else if (hours > 0) {
            this.updateLabels(this.labels?.final.hours);
        } else if (minutes > 0) {
            this.updateLabels(this.labels?.final.minutes);
        } else if (seconds > 0) {
            this.updateLabels(this.labels?.final.seconds);
        } else {
            this.updateLabels(this.labels?.ended);
        }
    };

    destroy(): void {
        this.countdown.unsubscribe(this.onTick);
        this.endDate = null;
        this.el = null;
    }

    init(el: HTMLElement | null): void {
        if (!this.gatherData(el)) {
            return this.destroy();
        }
        this.countdown.start(this.endDate);
        this.countdown.subscribe(this.onTick);
    }
}
