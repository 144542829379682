import { getClosestEl } from 'cms/js/utils/getEl';
import { reportError } from 'common/js/utils/error/error';
import z from 'zod';

export class RotundaTracking {
    private el: HTMLElement | null = null;
    private readonly eventSchema = z.object({
        event: z.string(),
        eventAction: z.string(),
        eventCategory: z.string(),
        eventLabel: z.string(),
    });

    private onClick = (event: Event) => {
        try {
            const rawData = getClosestEl(event.target, 'a')?.getAttribute('data-tracking') ?? '{}';
            const data = JSON.parse(rawData);
            if (this.eventSchema.safeParse(data)) {
                window.dataLayer?.push(data);
            }
        } catch (error) {
            reportError(error);
        }
    };

    destroy(): void {
        this.el?.removeEventListener('click', this.onClick);
        this.el = null;
    }

    init(el?: Element | undefined | null): void {
        const isHtmlEl = el instanceof HTMLElement;
        if (!isHtmlEl) {
            return;
        }
        this.el = el;
        this.el.addEventListener('click', this.onClick);
    }
}
