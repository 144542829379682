import memoize from 'lodash/memoize';

type TEnsureScriptLoaded = (src: string, props?: Record<string, unknown>) => Promise<boolean>;
export const ensureScriptLoaded: TEnsureScriptLoaded = memoize(
    (src, props) =>
        new Promise((resolve) => {
            const script = document.createElement('script');
            for (const key in props) {
                script[key] = props[key];
            }
            script['src'] = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        })
);
