import type { VoyageNextObserver, VoyageObserver } from './observer';
import { Observer } from './observer';

export interface VoyageObservers<T> {
    clear(): boolean;
    delete(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): boolean;
    get(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): VoyageObserver<T> | null;
    has(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): boolean;
    set(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): VoyageObserver<T>;
    next(value: T): void;
}

export class Observers<T> implements VoyageObservers<T> {
    private list = new Map<VoyageObserver<T> | VoyageNextObserver<T>, VoyageObserver<T>>();

    clear(): boolean {
        const hadObservable = this.list.size > 0;
        this.list.clear();
        return hadObservable;
    }

    delete(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): boolean {
        return this.list.delete(observerOrNext);
    }

    get(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): VoyageObserver<T> | null {
        return this.list.get(observerOrNext) || null;
    }

    has(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): boolean {
        return this.list.has(observerOrNext);
    }

    next(value: T): void {
        this.list.forEach((observer) => observer.next(value));
    }

    set(observerOrNext: VoyageObserver<T> | VoyageNextObserver<T>): VoyageObserver<T> {
        const observer = new Observer(observerOrNext);
        this.list.set(observerOrNext, observer);
        return observer;
    }
}
