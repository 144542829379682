import { ILibraryLazyLoader, LazyLibraryStorage } from 'types/library-lazy-loader';
import { ensureScriptLoaded } from './script-loading';

class LibraryLazyLoader implements ILibraryLazyLoader {
    private storage: Partial<LazyLibraryStorage> = {};
    private resolvers: Partial<Record<keyof LazyLibraryStorage, () => void>> = {};
    private loaders: Partial<Record<keyof LazyLibraryStorage, Promise<void>>> = {};

    public register = <T extends keyof LazyLibraryStorage>(libraryName: T, library: LazyLibraryStorage[T]) => {
        this.storage[libraryName] = library;
        this.resolvers[libraryName]?.();
    };

    public getLibrary = async <T extends keyof LazyLibraryStorage>(
        libraryName: T
    ): Promise<LazyLibraryStorage[T] | undefined> => {
        if (this.storage[libraryName]) {
            return this.storage[libraryName];
        }

        if (!this.loaders[libraryName]) {
            const { promise, resolve } = Promise.withResolvers();
            this.loaders[libraryName] = promise;
            this.resolvers[libraryName] = resolve;
        }

        const extLibSrc = window.LAZY_LIBRARY_SOURCES[libraryName];

        const isLoaded = !!extLibSrc && (await ensureScriptLoaded(extLibSrc));
        if (!isLoaded) {
            this.resolvers[libraryName]?.();
        }
        await this.loaders[libraryName];
        return this.storage[libraryName];
    };
}

if (!window.LibraryLazyLoader) {
    window.LibraryLazyLoader = new LibraryLazyLoader();
}
