import { getEl } from "@utils/getEl";
import { getFocusableNotDisabledIndexableElements } from 'js/common/utils/focus';

export class SkipToHashLink {
    private element: HTMLAnchorElement | null;
    private toElement: HTMLElement | null = null;

    constructor(element: HTMLAnchorElement | null) {
        this.element = element;
        if (this.element?.hash) {
            this.toElement = getEl(document, this.element.hash, HTMLElement);
        }
    }

    private navigate = () => {
        getFocusableNotDisabledIndexableElements(this.toElement)[0]?.focus();
        this.toElement?.scrollIntoView({ behavior: 'smooth' });
    };

    private onClick = (event: MouseEvent) => {
        event.preventDefault();
        this.navigate();
    }

    private onKeyDown = (event: KeyboardEvent) => {
        if (['Space', 'Enter'].includes(event.code)) {
            event.preventDefault();
            this.navigate();
        }
    }

    public mount = () => {
        this.element?.addEventListener('click', this.onClick)
        this.element?.addEventListener('keydown', this.onKeyDown)
    }

    public unmount = () => {
        this.element?.removeEventListener('click', this.onClick)
        this.element?.removeEventListener('keydown', this.onKeyDown)
    }
}
