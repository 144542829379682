import { type VoyageObserver } from '../../../../common/js/utils/subject';
import { type VVCurrencyState } from './types';

export class CurrencyUrlObserver implements VoyageObserver<VVCurrencyState> {
    private code: string = '';
    private readonly paramName = 'currencyCode';

    next({ selectedCurrency }): void {
        const isChanged = selectedCurrency.code !== this.code;
        this.code = selectedCurrency.code;
        if (!isChanged) {
            return;
        }
        const url = new URL(window.location.href);
        if (!url.searchParams.has(this.paramName)) {
            return;
        }
        url.searchParams.set(this.paramName, this.code);
        window.history.pushState(null, '', url);
    }
}
