import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import isValid from 'date-fns/isValid';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import { toLocalDate } from './to-local-date';

export const TIME = {
    SECONDS: 1000,
    MINUTES: 60 * 1000,
    HOURS: 60 * 60 * 1000,
    DAYS: 24 * 60 * 60 * 1000,
};

/** @deprecated Please consider using the `toLocalDate` method. The `toDate` is going to be deleted soon. */
export function toDate(rawDate?: Date | string | number | null | undefined): Date | null {
    if (rawDate === null || rawDate === undefined) {
        return null;
    }
    const date = rawDate instanceof Date ? rawDate : new Date(rawDate);
    return isValid(date) ? date : null;
}

export function getEndDate(value?: Date | string | number | null | undefined): number | null {
    const date = toLocalDate(value);
    return date ? endOfDay(date).valueOf() : null;
}

export function getStartDate(value?: Date | string | number | null | undefined): number | null {
    const date = toLocalDate(value);
    return date ? startOfDay(date).valueOf() : null;
}

export interface VVDateDiff {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export function diff(leftDate?: Date | string | number | null, rightDate?: Date | string | number | null): VVDateDiff {
    const startDate = toLocalDate(leftDate);
    const endDate = toLocalDate(rightDate);
    if (!startDate || !endDate || startDate >= endDate) {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    const days = differenceInDays(endDate, startDate);
    const restHours = addDays(startDate, days);
    const hours = differenceInHours(endDate, restHours);
    const restMinutes = addHours(restHours, hours);
    const minutes = differenceInMinutes(endDate, restMinutes);
    const restSeconds = addMinutes(restMinutes, minutes);
    const seconds = differenceInSeconds(endDate, restSeconds);
    return { days, hours, minutes, seconds };
}

export function toMonthEnd(rawDate?: Date | string | number | null | undefined): Date | null {
    const date = toLocalDate(rawDate);
    /**
     * To accurately calculate the end of the current month, our approach involves determining this
     * date based on the midpoint of the month. This strategy is specifically designed to prevent
     * issues related to timezone differences.
     *
     * For instance, a user in timezone that is 4 hours behind UTC could incorrectly receive
     * October 1st as the end of the month for a date October 1.
     */
    date?.setDate(15);
    return date ? endOfMonth(date) : null;
}

export function toMonthMiddle(rawDate?: Date | string | number | null | undefined): Date | null {
    const date = toLocalDate(rawDate);
    if (date === null) {
        return null;
    }
    const middle = Math.floor(getDaysInMonth(date) / 2);
    date.setDate(middle);
    date.setHours(0, 0, 0, 0);
    return date;
}

export function toMonthStart(rawDate?: Date | string | number | null | undefined): Date | null {
    const date = toLocalDate(rawDate);
    /**
     * To accurately calculate the start of the current month, our approach involves determining this
     * date based on the midpoint of the month. This strategy is specifically designed to prevent
     * issues related to timezone differences.
     *
     * For instance, a user in timezone that is 4 hours behind UTC could incorrectly receive
     * September 1st as the start of the month for a date October 1.
     */
    date?.setDate(15);
    return date ? startOfMonth(date) : null;
}

export function isMonthInPeriod(
    target: Date | string | number | null | undefined,
    start: Date | string | number | null | undefined,
    end: Date | string | number | null | undefined
): boolean {
    const targetDate = toMonthMiddle(target);
    if (!targetDate) {
        return true;
    }
    const startDate = toMonthStart(start);
    const endDate = toMonthEnd(end);
    return (!startDate || targetDate >= startDate) && (!endDate || targetDate <= endDate);
}

export function formatPeriod(
    startDate: Date | string | number | null | undefined,
    endDate: Date | string | number | null | undefined,
    formatter: (date: Date) => string | number = (date: Date) => format(date, 'MMM yyyy'),
    separator = ' - '
): string {
    return [startDate, endDate]
        .map((timestamp) => toDate(timestamp))
        .filter((maybeDate): maybeDate is Date => maybeDate !== null)
        .map(formatter)
        .join(separator);
}
