function checkType<T extends Element>(objectType: { new (): T }) {
    return (el: unknown): el is T => el instanceof objectType;
}

export function getClosestEl(parent: EventTarget | Element | null | undefined, selector: string): Element | null;
export function getClosestEl<T extends Element>(
    parent: EventTarget | Element | null | undefined,
    selector: string,
    searchType: { new (): T }
): T | null;
export function getClosestEl<T extends Element>(
    parent: EventTarget | Element | null | undefined,
    selector: string,
    searchType?: { new (): T }
): T | Element | null {
    const isEl = parent instanceof Element;
    if (!isEl || !selector) {
        return null;
    }
    const el = parent.closest(selector);
    return checkType(searchType || Element)(el) ? el : null;
}

export function getEl(parent: Document | Element | null | undefined, selector: string): Element | null;
export function getEl<T extends Element>(
    parent: Document | Element | null | undefined,
    selector: string,
    searchType: { new (): T }
): T | null;
export function getEl<T extends Element>(
    parent: Document | Element | null | undefined,
    selector: string,
    searchType?: { new (): T }
): T | Element | null {
    if (!parent || !selector) {
        return null;
    }
    const el = parent.querySelector(selector);
    return checkType(searchType || Element)(el) ? el : null;
}

export function getAllEl(parent: Document | Element | null | undefined, selector: string): Element[];
export function getAllEl<T extends Element>(
    parent: Document | Element | null | undefined,
    selector: string,
    searchType: { new (): T }
): T[];
export function getAllEl<T extends Element>(
    parent: Document | Element | null | undefined,
    selector: string,
    searchType?: { new (): T }
): T[] | Element[] {
    return selector
        ? Array.from(parent?.querySelectorAll(selector) ?? []).filter(checkType(searchType || Element))
        : [];
}
