export const checkUserAgent = target => navigator.userAgent.search(new RegExp(target, 'i')) >= 0;

export const checkAndroid = () => checkUserAgent('Android');

export const checkIOS = () => checkUserAgent('iPhone|iPad|iPod');

/** @public */
export const checkMobile = () => checkUserAgent('Android|iPhone|iPad|iPod');

export const getAgentName = () => navigator.userAgent.match(/Android|iPhone|iPad|iPod/i) || [];
