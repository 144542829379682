export default () => {
    (function (global) {
        global.vv.start = function (start) {
            if (/comp|inter|loaded/.test(document.readyState)) {
                start();
            } else {
                document.addEventListener('DOMContentLoaded', start, false);
            }
        };
    })(window);
};
