import { diff, type VVDateDiff } from '../date-utils/date-utils';
import { Subject, type VoyageSubject } from '../subject';

export interface VVCountdownSubject extends VoyageSubject<VVDateDiff> {
    start(date: Date | string | null): void;

    stop(): void;
}

export class CountdownSubject extends Subject<VVDateDiff> implements VVCountdownSubject {
    private interval = 1000;
    private key: number | null = null;

    constructor() {
        super(diff(null, null));
    }

    private tick = (date: Date | string | null): void => {
        this.next(diff(new Date(), date));
    };

    start(date: Date | string | null): void {
        this.stop();
        this.tick(date);
        this.key = setInterval(() => this.tick(date), this.interval) as unknown as number;
    }

    stop(): void {
        if (this.key) {
            clearInterval(this.key);
        }
    }
}
