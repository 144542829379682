const SERVER_DATE_FORMATTER = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
});

/**
 * Calculates the timezone offset for a given date. This is necessary because New York observes
 * Eastern Standard Time (EST) and Eastern Daylight Time (EDT) at different times of the year.
 * The offset determines whether New York is currently observing EST (UTC-5) or EDT (UTC-4).
 */
export function getServerOffset(date = new Date()) {
    /**
     * Note: Jest (jest-environment-jsdom) returns the current New York timezone no matter what:
     * in winter it's always EST timezone even if you format a summer date in EDT timezone. In real
     * environment it works as expected. We need to find a way how to write tests for EST/EDT timezones.
     */
    const timeZone =
        SERVER_DATE_FORMATTER.formatToParts(date).find((part) => part.type === 'timeZoneName')?.value ?? 'EST';
    const hours = timeZone === 'EST' ? 5 : 4; // EST or EDT
    const msToUtc = hours * 60 * 60 * 1000;
    return {
        iso: `-0${hours}:00`,
        msToLocal: msToUtc - date.getTimezoneOffset() * 60 * 1000,
        msToUtc,
        name: timeZone,
    };
}
