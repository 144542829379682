export default () => {
    (function (global) {
        function initScrollToView() {
            var counter = 1,
                maxCounter = 10,
                metaType = 'metaType';

            var intervalId = setInterval(function () {
                if (counter >= maxCounter) {
                    return clearInterval(intervalId);
                }

                var metaTypeId = global.vv.helpers.getQueryVariable(metaType);
                if (!metaTypeId) {
                    return clearInterval(intervalId);
                } else {
                    metaTypeId = metaTypeId.replace(' ', '-');
                }

                var el = document.getElementById(metaTypeId);
                if (!el) {
                    counter++;
                    return;
                }

                el.scrollIntoView();
                clearInterval(intervalId);
            }, 500);
        }

        function initSocialTrackingShareButton() {
            function tracking(elem) {
                if (!elem) {
                    return;
                }

                var link = elem.querySelector('a');

                if (link) {
                    link.href = link.href && link.href.replace(/—/g, '%E2%80%94');
                    var socialNetwork = link.dataset.sharetype.toLowerCase().trim();
                    var itineraryName = document
                        .querySelector('.FlyoutContent__heading')
                        .innerText.replace(/\s/g, '-')
                        .toLowerCase()
                        .trim();

                    dataLayer.push({
                        event: 'GTMevent',
                        eventCategory: 'VV-сhoose-voyage',
                        eventAction: 'social-share',
                        eventLabel: socialNetwork + '|' + itineraryName,
                    });
                }
            }

            document.addEventListener('click', function (event) {
                var target = event.target;
                var lookingClass = 'SocialLinks__icon';

                while (target != this) {
                    if (!target) {
                        break;
                    }

                    if (
                        target.classList &&
                        target.classList.contains(lookingClass) &&
                        target.parentNode.classList.contains('SocialLinks__icons')
                    ) {
                        tracking(target);
                        return;
                    }

                    target = target.parentNode;
                }
            });
        }

        global.vv.start(function () {
            initScrollToView();
            initSocialTrackingShareButton();
        });
    })(window);
};
