interface ErrorWithMessage {
    message: string;
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as Record<string, unknown>).message === 'string'
    );
}

export function reportError(error: unknown): void {
    if (isErrorWithMessage(error)) {
        return console.warn(error.message);
    }
    try {
        console.warn(JSON.stringify(error));
    } catch {
        console.warn(String(error));
    }
}
