export class CurrencyBroadcast {
    private readonly broadcastId = 'cmsCurrencyBroadcast';

    constructor(onChange: (code: string) => void) {
        window.addEventListener('storage', ({ key, newValue: code }: StorageEvent) => {
            if (key !== this.broadcastId || code === null) {
                return;
            }
            try {
                onChange(code);
                window.location.reload();
            } catch (error) {
                reportError(error);
            }
        });
    }

    emit(code: string): void {
        window.localStorage.setItem(this.broadcastId, code);
    }
}
