import * as z from 'zod';
import type { Subject } from '@common/js/utils/subject';

export const currencySchema = z.object({
    code: z.string(),
    label: z.string(),
    symbol: z.string(),
});

export const currenciesSchema = z.object({
    availableCurrencies: z.array(currencySchema),
    currencyCode: z.string(),
    countryIsoCode: z.string(),
});

export type VVCurrency = z.infer<typeof currencySchema>;
export type VVCurrencies = z.infer<typeof currenciesSchema>;

export interface VVCurrencyState {
    /** The list of available currencies for a user. */
    availableCurrencies: VVCurrency[];

    /** The `selectedCurrency` property contains a currency either selected by a user or provided by the VXP. */
    selectedCurrency: VVCurrency;
}

export interface VVCurrencySubject extends Subject<VVCurrencyState> {
    /** Get a selected currency code. */
    getCode(): string;

    /** Get a selected currency symbol. */
    getSymbol(): string;

    /**
     * Get a currency symbol by its code if the currency is in a list of the available currencies;
     * otherwise returns an empty string.
     */
    getSymbolByCode(code?: string | null): string;

    /** Find and set the selected currency by a currency code from the list of available options. */
    select(code: string): void;
}
