import { getEl } from 'cms/js/utils/getEl';
import { matchMinRectangle, VIEWPORT_MIN } from '@common/js/utils/match-media';

export class RotundaVisibility {
    private boundaryBottom: HTMLElement | null = null;
    private boundaryTop: HTMLElement | null = null;
    private el: HTMLElement | null = null;
    private isEditMode = false;
    private preventToggle = false;
    private hiddenClasses = ['opacity-0', 'scale-50', 'translate-y-[200%]'];
    private shownClasses = ['opacity-1', 'scale-100', 'translate-y-0'];

    private isScreenBiggerThanMobile = () => {
        return matchMinRectangle(VIEWPORT_MIN.TABLET, VIEWPORT_MIN.TABLET).matches;
    };

    private isVisible = () => {
        /**
         * If the viewport's width is more that 768px and height less than 400px then the Rotunda
         * could overlap the SuperButton component.
         */
        return this.isScreenBiggerThanMobile() || (this.boundaryTop?.getBoundingClientRect().y ?? 0) <= 0;
    };

    private toggle = (): void => {
        /**
         * The Rotunda can overlap footer functionality. We don't allow it to go beyond the body for that purpose.
         *
         * @see: https://virginvoyages.atlassian.net/browse/MSH-101572
         */
        if (!this.isEditMode && this.boundaryBottom) {
            const bottomRect = this.boundaryBottom?.getBoundingClientRect();
            const isBottomStuck = bottomRect && bottomRect?.top < window.innerHeight;
            if (isBottomStuck) {
                this.el?.style.setProperty('--rotunda-bottom-offset', `${bottomRect.height / 16}rem`);
            } else {
                this.el?.style.removeProperty('--rotunda-bottom-offset');
            }
            this.el?.classList.toggle('fixed', !isBottomStuck);
            this.el?.classList.toggle('absolute', isBottomStuck);
        }

        if (!this.preventToggle) {
            this.setShown(this.isVisible());
        }
    };

    private setShown = (isVisible: boolean) => {
        this.el?.classList[isVisible ? 'add' : 'remove']?.(...this.shownClasses);
        this.el?.classList[!isVisible ? 'add' : 'remove']?.(...this.hiddenClasses);
    };

    private dialogOpenedHandler = () => {
        if (!this.isScreenBiggerThanMobile()) {
            this.preventToggle = true;
            this.setShown(false);
        }
    };

    private dialogClosedHandler = () => {
        this.preventToggle = false;
        this.setShown(this.isVisible());
    };

    destroy(): void {
        window.removeEventListener('scroll', this.toggle);
        window.removeEventListener('resize', this.toggle);
        window.removeEventListener('dialogOpened', this.dialogOpenedHandler);
        window.removeEventListener('dialogClosed', this.dialogClosedHandler);
        this.el = null;
    }

    init(el?: Element | undefined | null): void {
        this.destroy();
        this.el = el instanceof HTMLElement ? el : null;
        if (this.el) {
            this.isEditMode = this.el.getAttribute('data-edit-mode') === 'true';
            this.boundaryBottom = getEl(document, '.js-Footer', HTMLElement);
            this.boundaryTop = getEl(document, 'h1', HTMLElement);
            this.toggle();
            window.addEventListener('resize', this.toggle);
            window.addEventListener('scroll', this.toggle);
            window.addEventListener('dialogOpened', this.dialogOpenedHandler);
            window.addEventListener('dialogClosed', this.dialogClosedHandler);
        }
    }
}
