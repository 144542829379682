import { makeZoomLevelGetter } from '@utils/make-zoom-level-getter';
import { getEl } from '@utils/getEl';

export class RepositionBookingAccountElOnZoomLevelChange {
    private hasRepositioned = false;

    private originalStyles = {
        bookingAccEl: {
            position: CSSStyleDeclaration['position'],
        },
        menuEl: {
            marginBottom: CSSStyleDeclaration['marginBottom'],
        },
    };

    private bookingAccEl = getEl(document.body, '#booking-account', HTMLElement);
    private menuEl = getEl(document.body, '.nav-bar2__menu', HTMLElement);
    private originalParentEl = getEl(document.body, '.nav-bar2__item--right2', HTMLElement);

    private getZoomLevel = makeZoomLevelGetter();

    private saveOriginalStyles = () => {
        this.originalStyles.bookingAccEl.position = this.bookingAccEl?.style.position;
        this.originalStyles.menuEl.marginBottom = this.menuEl?.style.marginBottom;
    };

    private applyNewStyles = () => {
        if (!this.bookingAccEl || !this.menuEl) {
            return;
        }
        this.bookingAccEl.style.position = 'static';
        this.menuEl.style.marginBottom = '0px';
    };

    private restoreOriginalStyles = () => {
        if (!this.bookingAccEl || !this.menuEl) {
            return;
        }
        this.bookingAccEl.style.position = this.originalStyles.bookingAccEl.position;
        this.menuEl.style.marginBottom = this.originalStyles.menuEl.marginBottom;
    };

    private handleGreaterZoomLevel = () => {
        if (!this.bookingAccEl || !this.menuEl) {
            return;
        }
        this.saveOriginalStyles();
        this.applyNewStyles();

        // move the element to the new position
        this.menuEl.appendChild(this.bookingAccEl);
        this.hasRepositioned = true;
    };

    private handleLesserZoomLevel = () => {
        if (!this.originalParentEl || !this.bookingAccEl) {
            return;
        }

        this.restoreOriginalStyles();

        // move the element back to its original position
        this.originalParentEl.insertBefore(this.bookingAccEl, this.originalParentEl.firstChild);
        this.hasRepositioned = false;
    };

    /**
     * Reposition account booking element on zoom change.
     * Specifically on zoom in, as the elements start stacking on top of each other on zoom levels > 3
     */
    private updateElementPosition = () => {
        const zoomLevel = this.getZoomLevel();

        // if zoom level more than 300% move the element to a new location
        if (zoomLevel >= 3 && !this.hasRepositioned) {
            this.handleGreaterZoomLevel();
        }

        // if zoom level lesser than 300% move the element to its original location
        if (zoomLevel < 3 && this.hasRepositioned) {
            this.handleLesserZoomLevel();
        }
    };

    watch = () => {
        this.updateElementPosition();
        window.addEventListener('resize', this.updateElementPosition);
    };

    cleanUp = () => {
        window.removeEventListener('resize', this.updateElementPosition);
    };
}
